import { Flex } from "~/components/ui-library";
import { ConditionValue } from "~/pages/space/Workspaces/components/QueryBuilder/Condition/ConditionValue";
import { alpha, Button, ButtonProps, Typography } from "@mui/material";
import { ValuesDropdownAutocomplete } from "~/pages/space/Workspaces/components/QueryBuilder/AutocompleteDropdown/ValuesDropdownAutocomplete";
import React, { useRef } from "react";
import { Close } from "@mui/icons-material";

export type ConditionValuesProps = {
  values: string[];
  showRemoveConditionButton: boolean;
  onRemove: ButtonProps["onClick"];
  isKeySelected: boolean;
  onValueRemove: (value: string) => void;
  name:
    | `selections.${number}.conditions.${number}.stringCondition.values`
    | `selections.${number}.conditions.${number}.intCondition.values`;
  options: Array<{ label: string; value: string }>;
  isReadonly: boolean;
};

export const ConditionValues = ({
  isKeySelected,
  values,
  showRemoveConditionButton,
  onRemove,
  onValueRemove,
  name,
  options,
  isReadonly,
}: ConditionValuesProps) => {
  const anchorRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Flex
        gap={2}
        alignItems="center"
        flexWrap="wrap"
        flexGrow={1}
        sx={{ position: "relative" }}
        ref={anchorRef}
      >
        {values.map((value, index) => (
          <Flex alignItems="center" gap={2} key={value}>
            <ConditionValue
              value={value}
              onClick={() => onValueRemove(value)}
              isReadonly={isReadonly}
            />
            {index !== values.length - 1 && (
              <Typography fontWeight={700}> OR </Typography>
            )}
          </Flex>
        ))}
        {isKeySelected && !isReadonly && (
          <ValuesDropdownAutocomplete
            values={values}
            name={name}
            options={options}
            anchorRef={anchorRef}
          />
        )}
        {showRemoveConditionButton && (
          <Flex
            sx={{
              flex: "1 0 auto",
              justifyContent: "flex-end",
              visibility: "hidden",
            }}
            className="delete-condition-button"
          >
            <Button
              variant="text"
              sx={{
                color: (theme) => alpha(theme.palette.error.main, 0.5),
              }}
              endIcon={<Close />}
              onClick={onRemove}
            >
              DELETE CONDITION
            </Button>
          </Flex>
        )}
      </Flex>
    </>
  );
};
