import { AddIcon } from "~/components/icons";
import { Button, ButtonProps } from "@mui/material";
import { Flex } from "~/components/ui-library";
import React from "react";

type AddSelectionRowProps = {
  onClick: ButtonProps["onClick"];
  disabled: boolean;
};

export const AddSelectionRow = ({
  onClick,
  disabled,
}: AddSelectionRowProps) => {
  return (
    <Flex alignItems="center">
      <Button
        variant="text"
        disabled={disabled}
        sx={{ color: (theme) => theme.palette.secondary.light }}
        onClick={onClick}
        endIcon={<AddIcon />}
      >
        ADD ASSET SELECTION
      </Button>
    </Flex>
  );
};
