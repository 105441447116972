import { NumberDot } from "~/components/guides/components";
import { Flex } from "~/components/ui-library";
import { Box, Typography, useTheme } from "@mui/material";
import { CreateWorkspaceFormFields } from "~/pages/space/Workspaces/components/CreateWorkspace";
import React from "react";
import { useFormContext } from "react-hook-form";
import { CreateWorkspaceFormInput } from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspacePage";

type CreateWorkspaceStepTwoProps = {};

export const CreateWorkspaceStepTwo = ({}: CreateWorkspaceStepTwoProps) => {
  const theme = useTheme();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext<CreateWorkspaceFormInput>();

  return (
    <Flex alignItems="flex-start">
      <NumberDot
        number={2}
        backgroundColor={theme.palette.background.lightest}
      />
      <Flex flexDirection="column" gap={1} width="100%">
        <Typography
          variant="h5"
          component="p"
          fontWeight={700}
          sx={{
            fontSize: { xs: 16, sm: 24 },
          }}
        >
          Describe your workspace
        </Typography>
        <Typography variant="body2" color="text.secondary" component="p">
          Naming your workspace and providing a representative description of
          the purpose of the workspace makes both collaboration and
          documentation easier.
        </Typography>
        <Box mt={5}>
          <CreateWorkspaceFormFields register={register} errors={errors} />
        </Box>
      </Flex>
    </Flex>
  );
};
