import { Button } from "@mui/material";
import { AddIcon } from "~/components/icons";
import React from "react";

type ConditionValueProps = {
  value: string;
  onClick: () => void;
  isReadonly: boolean;
};

export const ConditionValue = ({
  value,
  onClick,
  isReadonly,
}: ConditionValueProps) => {
  return (
    <Button
      variant="outlined-contained"
      color="primary"
      endIcon={
        !isReadonly && <AddIcon fontSize="small" sx={{ rotate: "45deg" }} />
      }
      sx={{ flexShrink: 0 }}
      onClick={onClick}
    >
      {value}
    </Button>
  );
};
