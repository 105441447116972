import { Flex } from "~/components/ui-library";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SetExceptionDialog } from "~/components/exceptions/set-exception-dialog";
import {
  ComplianceFrameworkMutationAction,
  ComplianceFrameworkState,
  ControlState,
  DocumentType,
  GetComplianceControlDocument,
  GetComplianceFrameworkControlsDocument,
  GetComplianceFrameworkDocument,
  GetComplianceFrameworksDocument,
  GetListFrameworksDocument,
  ListFrameworksFilterState,
  useApplyFrameworkMutation,
} from "~/operations";
import { Space } from "~/lib/types";
import { FrameworkIcon, useFrameworkIcon } from "./framework-icon";
import { Markdown } from "~/components/markdown";
import { ExportButton } from "../components/export-framework";
import { ComplianceFramework } from "./framework";
import { ComplianceControl } from "../control/control";
import { ExceptionFlag } from "./exception-flag/exception-flag";
import { useExceptions } from "~/components/exceptions/use-exceptions";
import { useColorMode } from "~/providers/color-mode";
import { mapSelectedEntitiesToString } from "~/components/exceptions/utils";
import { FrameworkStateMenu } from "~/pages/compliance/framework/components/FrameworkStateMenu";
import { enqueueSnackbar } from "notistack";
import { getError } from "~/lib/handle-error";
import { CircularIconButton } from "../components/CircularIconButton";
import { IconButtonType } from "../components/DynamicButtonIcon";

type Framework = {
  framework: ComplianceFramework;
  control?: never;
};

type Control = {
  control: ComplianceControl;
  framework?: never;
};

type Props = {
  space: Space;
} & (Framework | Control);

export function FrameworkHeader({ space, framework, control }: Props) {
  const [expanded, setExpanded] = useState(false);
  const { iconKey } = useFrameworkIcon({ framework });
  const [searchParams, _setSearchParams] = useSearchParams();
  const frameworkMrn = searchParams.get("frameworkMrn");
  const { mode } = useColorMode();
  let navigate = useNavigate();

  const [applyFramework] = useApplyFrameworkMutation({
    refetchQueries: [
      // ComplianceFrameworkDocument and GetComplianceFrameworksDocument should
      // both be removed after isFeatureEnabled("Framework States") is removed and
      // the new framework flow is deployed
      {
        query: GetComplianceFrameworkDocument,
        variables: {
          input: {
            frameworkMrn,
            scopeMrn: space.mrn,
          },
        },
      },
      {
        query: GetComplianceFrameworksDocument,
        variables: {
          input: {
            scopeMrn: space.mrn,
          },
        },
      },
      {
        query: GetListFrameworksDocument,
        variables: {
          input: {
            scopeMrn: space.mrn,
            filterState: ListFrameworksFilterState.Active,
          },
        },
      },
    ],
  });

  const {
    isSettingException,
    handleSetExceptionModalClose,
    handleSetExceptionModalOpen,
    handleRemoveException,
    handleSetException,
    loading: exceptionsLoading,
  } = useExceptions({
    scopeMrns: [space.mrn],
    controlMrns: mapSelectedEntitiesToString([{ mrn: control?.mrn || "" }]),
    refetchQueries: [
      {
        query: GetComplianceFrameworkDocument,
        variables: {
          input: {
            frameworkMrn,
            scopeMrn: space.mrn,
          },
        },
      },
      {
        query: GetComplianceControlDocument,
        variables: {
          input: {
            controlMrn: control?.mrn,
            frameworkMrn,
            scopeMrn: space.mrn,
          },
        },
      },
      {
        query: GetComplianceFrameworkControlsDocument,
        variables: {
          input: {
            frameworkMrn,
            scopeMrn: space.mrn,
          },
          controlsInput: {
            after: null,
            limit: 50,
          },
        },
      },
    ],
  });

  // open and close the show more accordion when content is present
  const handleShowMore = () => {
    setExpanded((current) => !current);
  };

  // loop through the authors and create a string of names
  const authors = framework
    ? framework.authors?.map((author) => author.name).join(", ")
    : null;

  // get state of control or framework for powerSwitch
  const exceptionFlagState = framework ? framework.state : control.state;

  const handleFrameworkStateChange = async (
    state: ComplianceFrameworkMutationAction,
  ) => {
    if (!framework) return;

    try {
      await applyFramework({
        variables: {
          input: {
            action: state,
            frameworkMrn: framework.mrn,
            scopeMrn: space.mrn,
          },
        },
      });
      if (state === ComplianceFrameworkMutationAction.Disable) {
        navigate(`/space/compliance?spaceId=${space.id}`);
      }
    } catch (error) {
      const msg = getError(error);
      enqueueSnackbar(
        `Failed to change framework state ${msg ? ": " + msg : null}`,
        { variant: "error" },
      );
    }
  };

  // handle the powerswitch click when viewing a control
  // if currently active => open exceptions modal
  // if currently snoozed => toggle to active
  // if currently disabled => toggle to active
  const handleControlOptions = () => {
    if (!control) return;

    const action =
      control.state === ControlState.Active ? "openModal" : "toggleActive";

    switch (action) {
      case "openModal":
        handleSetExceptionModalOpen();
        break;
      case "toggleActive":
        handleRemoveException();
        break;
      default:
        return;
    }
  };

  const title = framework ? framework.name : control.title;
  const description = framework ? framework.description : control.description;
  // clip the description at 60 words
  const clippedDescription = description
    ? description.split(" ").slice(0, 60).join(" ")
    : "";
  // the rest of the clipped description if it exists
  const moreDescription = description
    ? description.split(" ").length > 60
      ? description.split(" ").slice(60).join(" ")
      : ""
    : "";

  return (
    <Fragment>
      <Grid container rowGap={3} columnSpacing={2} sx={{ mb: 3 }}>
        {framework?.state === ComplianceFrameworkState.Preview && (
          <Grid item xs={12}>
            <PreviewAlert />
          </Grid>
        )}

        <Grid item xs={12} md sx={{ display: "flex", flexDirection: "column" }}>
          <Flex alignItems="center">
            <Box sx={{ mr: iconKey ? 2 : 0 }}>
              <FrameworkIcon iconKey={iconKey} />
            </Box>
            <Typography variant="h4" fontWeight={700} textTransform="uppercase">
              {title}
            </Typography>
          </Flex>
          <Flex>
            {authors && (
              <Typography
                variant="caption"
                color="text.secondary"
                fontWeight={700}
                sx={{ mr: 3 }}
              >
                {authors}
              </Typography>
            )}
          </Flex>
        </Grid>
        <Grid
          item
          xs={12}
          md
          sx={{ display: "flex", alignItems: "start", justifyContent: "end" }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {frameworkMrn && (
              <Fragment>
                <ExportButton
                  space={space}
                  documentType={
                    framework
                      ? DocumentType.FrameworkReport
                      : DocumentType.ControlReport
                  }
                  title={title}
                  frameworkMrn={frameworkMrn}
                  {...(control && { controlMrn: control.mrn })}
                  size="large"
                />
                <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
              </Fragment>
            )}
            {framework ? (
              <>
                <Fragment>
                  <CircularIconButton
                    title={"Remove Framework"}
                    iconType={IconButtonType.Delete}
                    sx={{ color: "error.main", mr: 2 }}
                    onClick={() =>
                      handleFrameworkStateChange(
                        ComplianceFrameworkMutationAction.Disable,
                      )
                    }
                  />
                  <FrameworkStateMenu
                    state={framework.state}
                    onStateChange={handleFrameworkStateChange}
                  />
                </Fragment>
              </>
            ) : (
              <ExceptionFlag
                state={exceptionFlagState}
                onClick={handleControlOptions}
              />
            )}
          </Box>
        </Grid>

        {description.length > 0 && (
          <Grid item xs={12}>
            <Accordion
              expanded={expanded}
              disableGutters
              sx={{
                px: 0,
                background: "transparent",
                boxShadow: "none",
                "&:before": { display: "none" },
                "& .markdown": {
                  p: 0,
                  m: 0,
                  fontSize: (theme) => theme.spacing(2),
                  fontWeight: 600,
                  color: "text.secondary",
                  userSelect: "text",
                  cursor: "text",
                },
              }}
            >
              <AccordionSummary
                sx={{
                  px: 0,
                  m: 0,
                  "& .MuiAccordionSummary-content": { m: 0 },
                }}
              >
                <Markdown
                  className="markdown"
                  source={`${clippedDescription}${
                    moreDescription && !expanded && "..."
                  }`}
                />
              </AccordionSummary>
              <AccordionDetails
                sx={{ p: 0, m: 0, fontWeight: 600, color: "text.secondary" }}
              >
                {moreDescription && (
                  <Markdown className="markdown" source={moreDescription} />
                )}
              </AccordionDetails>
            </Accordion>
            {/* if there is more description content, view show more button */}
            {moreDescription && (
              <Button
                variant="text"
                onClick={handleShowMore}
                sx={{
                  px: 0,
                  py: 0.5,
                  mt: -0.75,
                  background: "transparent",
                  color: "secondary.light",
                  textTransform: "none",
                  "&:hover": { background: "transparent" },
                }}
              >
                Show {expanded ? "less" : "more"}
              </Button>
            )}
          </Grid>
        )}
      </Grid>

      {control && frameworkMrn && (
        <SetExceptionDialog
          open={isSettingException}
          onClose={handleSetExceptionModalClose}
          onSave={handleSetException}
          loading={exceptionsLoading}
          target="control"
          role="compliance"
        />
      )}
    </Fragment>
  );
}

const PreviewAlert = () => {
  return (
    <Alert variant="outlined" severity="info">
      <Typography fontWeight={500}>
        This compliance framework is in PREVIEW
      </Typography>
      <Typography variant="body2">
        You can see preliminary progress toward compliance with this framework,
        make configuration changes, and customize details.
      </Typography>
    </Alert>
  );
};
