import { Select } from "@mui/material";
import { KeyboardArrowDownIcon } from "~/components/icons";
import { ReactNode } from "react";

type BaseOperatorDropdownProps = {
  renderContent: () => ReactNode;
  defaultValue?: string;
  isReadonly?: boolean;
};

export const BaseOperatorDropdown = ({
  defaultValue,
  renderContent,
  isReadonly,
  ...rest
}: BaseOperatorDropdownProps) => {
  return (
    <Select
      disabled={isReadonly}
      variant="outlined"
      IconComponent={KeyboardArrowDownIcon}
      size="small"
      displayEmpty
      defaultValue={defaultValue || ""}
      sx={{
        background: (theme) => theme.palette.background.paper,
        "& .MuiSelect-icon": {
          opacity: 0.5,
        },
        px: 0,
      }}
      {...rest}
    >
      {renderContent()}
    </Select>
  );
};
