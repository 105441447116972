import React from "react";
import { useViewer } from "~/providers/viewer";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { Grid2 as Grid, TextField, Typography } from "@mui/material";
import { ValidationMessage } from "~/pages/integrations/validations/helpers";
import { Validations } from "~/lib/validations";
import { Flex } from "~/components/ui-library";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { CreateWorkspaceFormInput } from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspacePage";

export type CreateWorkspaceProps = {
  register: UseFormRegister<CreateWorkspaceFormInput>;
  errors: FieldErrors<CreateWorkspaceFormInput>;
};

export function CreateWorkspaceFormFields({
  register,
  errors,
}: CreateWorkspaceProps) {
  const { viewer } = useViewer();

  if (!viewer) {
    return <LoadingPage what="workspaces" />;
  }

  if (viewer.organizations?.length === 0) {
    return <LoadingFailedPage what="organization" />;
  }

  return (
    <Grid container size={12} gap={2}>
      <Flex flexDirection="column" sx={{ width: "100%" }} gap={1}>
        <Typography fontWeight={700}>Name</Typography>
        <TextField
          id="name-input"
          fullWidth
          placeholder="What’s a unique name for this workspace?"
          variant="outlined"
          slotProps={{
            input: {
              sx: { backgroundColor: "code.background" },
            },
          }}
          error={Boolean(errors.name)}
          helperText={
            Boolean(errors.name) && <ValidationMessage error={errors.name} />
          }
          disabled={false}
          {...register("name", {
            validate: {
              required: (value) => {
                if (
                  value == null ||
                  !value.match(Validations.nameInvalidLengthRegex)
                ) {
                  return Validations.nameInvalidLengthErrorMsg;
                } else if (
                  !value.match(Validations.nameInvalidCharactersRegex)
                ) {
                  return Validations.nameInvalidCharactersErrorMsg;
                }

                return true;
              },
            },
          })}
        />
      </Flex>
      <Flex flexDirection="column" sx={{ width: "100%" }} gap={1}>
        <Typography fontWeight={700}>
          Description
          <>
            {" "}
            <Typography component="span" color="text.secondary">
              (optional)
            </Typography>
          </>
        </Typography>
        <TextField
          id="description-input"
          fullWidth
          multiline
          minRows={3}
          placeholder="What is this workspace going to be used for?"
          variant="outlined"
          disabled={false}
          slotProps={{
            input: {
              sx: { backgroundColor: "code.background" },
            },
          }}
          {...register("description")}
        />
      </Flex>
    </Grid>
  );
}
