import { Flex } from "~/components/ui-library";
import { ButtonProps, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { CreateWorkspaceFormInput } from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspacePage";
import { KeysDropdownAutocomplete } from "../AutocompleteDropdown/KeysDropdownAutocomplete";
import { IndentIcon } from "~/components/icons";
import React from "react";
import { ConditionValue, ConditionValues } from ".";
import { useConditionRow } from "~/pages/space/Workspaces/components/QueryBuilder/Condition/hooks";
import { OperatorDropdown } from "~/pages/space/Workspaces/components/QueryBuilder/OperatorDropdown/OperatorDropdown";
import {
  WorkspaceSelectionConditionNumericOperator,
  WorkspaceSelectionConditionOperator,
  WorkspaceSelectionConditionStringOperator,
} from "~/operations";

export type ConditionRowProps = {
  selectionIndex: number;
  conditionIndex: number;
  onRemove: ButtonProps["onClick"];
  isReadonly: boolean;
};

export const ConditionRow = ({
  conditionIndex,
  selectionIndex,
  onRemove,
  isReadonly,
}: ConditionRowProps) => {
  const { register } = useFormContext<CreateWorkspaceFormInput>();

  const {
    showIncludeLabel,
    showConditionOperator,
    showConditionKeyLabel,
    isKeySelected,
    conditionKeys,
    conditionValues,
    conditionValuesOptions,
    showStringOperatorDropdown,
    showIntOperatorDropdown,
    selectedConditionKeyLabel,
    conditionValuesFieldName,
    handleConditionValueRemove,
    handleConditionKeyRemove,
  } = useConditionRow({
    selectionIndex,
    conditionIndex,
    isReadonly,
  });

  return (
    <Flex
      gap={2}
      alignItems="flex-start"
      sx={{
        "&:hover .delete-condition-button": {
          visibility: "visible",
        },
      }}
    >
      {showIncludeLabel && (
        <Typography sx={{ fontWeight: 700, p: 1 }}>INCLUDE:</Typography>
      )}
      {showConditionOperator && (
        <Flex alignItems="center" gap={2} ml={7}>
          <IndentIcon sx={{ color: (theme) => theme.palette.text.disabled }} />
          <OperatorDropdown
            type="condition"
            defaultValue={WorkspaceSelectionConditionOperator.And}
            isReadonly={isReadonly}
            {...register(
              `selections.${selectionIndex}.conditions.${conditionIndex}.operator`,
            )}
          />
        </Flex>
      )}
      {showConditionKeyLabel ? (
        <ConditionValue
          value={selectedConditionKeyLabel || ""}
          onClick={handleConditionKeyRemove}
          isReadonly={isReadonly}
        />
      ) : (
        conditionValues.length === 0 &&
        !isReadonly && (
          <KeysDropdownAutocomplete
            name={`selections.${selectionIndex}.conditions.${conditionIndex}.formKey`}
            groups={conditionKeys}
          />
        )
      )}
      {showStringOperatorDropdown && (
        <OperatorDropdown
          type="string"
          isReadonly={isReadonly}
          defaultValue={WorkspaceSelectionConditionStringOperator.Equal}
          {...register(
            `selections.${selectionIndex}.conditions.${conditionIndex}.stringCondition.operator`,
          )}
        />
      )}
      {showIntOperatorDropdown && (
        <OperatorDropdown
          type="numeric"
          isReadonly={isReadonly}
          defaultValue={WorkspaceSelectionConditionNumericOperator.Equal}
          {...register(
            `selections.${selectionIndex}.conditions.${conditionIndex}.intCondition.operator`,
          )}
        />
      )}
      <ConditionValues
        values={conditionValues}
        options={conditionValuesOptions}
        showRemoveConditionButton={conditionIndex !== 0 && !isReadonly}
        onRemove={onRemove}
        isKeySelected={isKeySelected}
        onValueRemove={handleConditionValueRemove}
        name={conditionValuesFieldName}
        isReadonly={isReadonly}
      />
    </Flex>
  );
};
