import { Flex } from "~/components/ui-library";
import { Box, Grid2 as Grid, Typography } from "@mui/material";
import { AddButton } from "~/components/add-button";
import React from "react";

type CreateWorkspaceHeaderProps = {
  spaceId: string;
};

export const CreateWorkspaceHeader = ({
  spaceId,
}: CreateWorkspaceHeaderProps) => {
  return (
    <Grid container size={12} gap={2}>
      <Box
        id="create-new-space-header"
        mb={6}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <Flex flexDirection="column">
          <Typography
            variant="h4"
            fontWeight={700}
            textTransform="uppercase"
            sx={{ mb: 1 }}
          >
            Create a Workspace
          </Typography>
          <Typography>
            Create a new workspace to structure a project, focus on specific
            assets, define criteria you want to monitor, or something entirely
            different – it’s up to you! Workspaces will dynamically pull in
            everything from this space that matches the criteria you define
            below.
          </Typography>
        </Flex>
        <Flex id="create-new-workspace-actions" alignItems="center" gap={4}>
          <AddButton
            id="workspace"
            href={`/space/workspaces?spaceId=${spaceId}`}
            aria-label="Create New Workspace"
            close
          />
        </Flex>
      </Box>
    </Grid>
  );
};
