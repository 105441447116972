import { alpha, MenuItem } from "@mui/material";
import { ReactNode } from "react";

export type BaseMenuItemProps = {
  label: string;
  value: unknown;
  children?: ReactNode;
  onClick?: (value: unknown) => void;
};

export const BaseMenuItem = ({
  label,
  children,
  onClick,
  value,
}: BaseMenuItemProps) => {
  return (
    <MenuItem
      key={label}
      value={label}
      sx={{
        "&.MuiMenuItem-root": {
          p: 0.5,
          px: 1,
        },
        "&:hover": {
          backgroundColor: (theme) =>
            `${alpha(theme.palette.primary.main, 0.2)}`,
        },
      }}
      onClick={() => onClick?.(value)}
    >
      {children}
    </MenuItem>
  );
};
