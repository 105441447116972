import {
  AddConditionRow,
  ConditionRow,
} from "~/pages/space/Workspaces/components/QueryBuilder/Condition";
import { Flex } from "~/components/ui-library";
import { ButtonProps, Paper } from "@mui/material";
import React from "react";
import { SelectionRowHeader } from "~/pages/space/Workspaces/components/QueryBuilder/SelectionRow/SelectionRowHeader";
import { useSelectionRow } from "~/pages/space/Workspaces/components/QueryBuilder/SelectionRow/hooks";

type SelectionRowProps = {
  selectionIndex: number;
  onRemove: ButtonProps["onClick"];
  showRemoveSelectionButton: boolean;
  isReadonly: boolean;
};

export const SelectionRow = ({
  selectionIndex,
  onRemove,
  showRemoveSelectionButton,
  isReadonly,
}: SelectionRowProps) => {
  const { canAddCondition, handleAddCondition, handleRemoveCondition, fields } =
    useSelectionRow({
      selectionIndex,
    });

  return (
    <Paper component={Flex} flexDirection="column" gap={2} sx={{ p: 3 }}>
      <SelectionRowHeader
        showRemoveSelectionButton={showRemoveSelectionButton}
        selectionIndex={selectionIndex}
        onRemove={onRemove}
      />
      <Flex gap={4} flexWrap="wrap" flexDirection="column">
        {fields.map((_, conditionIndex) => {
          return (
            <ConditionRow
              conditionIndex={conditionIndex}
              selectionIndex={selectionIndex}
              onRemove={() => handleRemoveCondition(conditionIndex)}
              key={conditionIndex}
              isReadonly={isReadonly}
            />
          );
        })}
      </Flex>
      {!isReadonly && (
        <AddConditionRow
          disabled={!canAddCondition}
          onClick={handleAddCondition}
        />
      )}
    </Paper>
  );
};
