import { Paper } from "@mui/material";
import { Flex } from "~/components/ui-library";
import {
  ArrowUpwardOutlined,
  SubdirectoryArrowLeft,
} from "@mui/icons-material";
import * as React from "react";

export const KeyboardHints = () => {
  return (
    <Paper
      sx={{
        p: 2,
        color: (theme) => theme.palette.text.disabled,
        fontWeight: 600,
      }}
      component={Flex}
      gap={2}
      justifyContent="center"
      alignItems="center"
    >
      <Flex gap={1}>
        <Flex
          sx={{
            border: (theme) => `1px solid ${theme.palette.text.disabled}`,
            borderRadius: "4px",
            fontSize: "12px",
            p: "5px",
          }}
          alignItems="center"
        >
          <ArrowUpwardOutlined sx={{ fontSize: "11px" }} />
        </Flex>
        <Flex
          sx={{
            border: (theme) => `1px solid ${theme.palette.text.disabled}`,
            color: (theme) => theme.palette.text.disabled,
            borderRadius: "4px",
            fontSize: "12px",
            p: "5px",
          }}
          alignItems="center"
        >
          <ArrowUpwardOutlined sx={{ rotate: "180deg", fontSize: "11px" }} />
        </Flex>
        Navigate
      </Flex>
      <Flex
        sx={{
          border: (theme) => `1px solid ${theme.palette.text.disabled}`,
          borderRadius: "4px",
          px: 1,
          py: 0.5,
          fontSize: "12px",
          lineHeight: "normal",
        }}
        alignItems="center"
        gap={0.5}
      >
        <SubdirectoryArrowLeft sx={{ fontSize: "11px" }} />
        ENTER
      </Flex>
      Select
      <Flex
        sx={{
          border: (theme) => `1px solid ${theme.palette.text.disabled}`,
          borderRadius: "4px",
          px: 1,
          py: 0.5,
          fontSize: "12px",
          lineHeight: "normal",
        }}
        alignItems="center"
      >
        ESC
      </Flex>
      Close
    </Paper>
  );
};
