import * as React from "react";
import { ReactElement, useEffect } from "react";
import {
  Box,
  ClickAwayListener,
  Grow,
  InputAdornment,
  Paper,
  Popper,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { SearchIcon } from "~/components/icons";
import { KeyboardHints } from "./KeyboardHints";

type BaseAutocompleteProps = {
  open: boolean;
  onClose: () => void;
  anchorRef: React.RefObject<HTMLElement>;
  onSearchInputChange: TextFieldProps["onChange"];
  placeholder?: string;
  showKeyboardHint?: boolean;
  renderContent?: () => ReactElement;
};

export function BaseAutocomplete({
  onClose,
  open,
  anchorRef,
  onSearchInputChange,
  showKeyboardHint,
  placeholder,
  renderContent,
}: BaseAutocompleteProps) {
  const handleEscapePress = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleEscapePress);

    return () => {
      window.removeEventListener("keydown", handleEscapePress);
    };
  }, []);

  return (
    <Popper
      sx={{
        zIndex: 1,
        minWidth: "100%",
        "& > .MuiPaper-root": {
          maxWidth: 440,
        },
      }}
      open={open}
      anchorEl={anchorRef.current}
      placement="bottom-start"
      transition
      disablePortal
      modifiers={[{ name: "flip", enabled: false }]}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: "left top" }}>
          <Paper
            sx={{
              border: "1px solid",
              borderColor: "primary.main",
              borderTopLeftRadius: 0,
              mt: "-1px",
              backgroundColor: "background.default",
            }}
          >
            <ClickAwayListener onClickAway={onClose}>
              <Box sx={{ minWidth: "100px" }}>
                <TextField
                  color="secondary"
                  placeholder={placeholder}
                  size="small"
                  onChange={onSearchInputChange}
                  sx={{ width: "100%" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    ),
                    sx: {
                      bgcolor: "code.background",
                      m: 2,
                      mb: 0,
                    },
                  }}
                />

                {renderContent?.()}

                {showKeyboardHint && <KeyboardHints />}
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
