import { Button, ButtonProps } from "@mui/material";
import { Flex } from "~/components/ui-library";
import { AddIcon, IndentIcon } from "~/components/icons";
import React from "react";

type AddConditionRowProps = {
  onClick: ButtonProps["onClick"];
  disabled: boolean;
};

export const AddConditionRow = ({
  onClick,
  disabled,
}: AddConditionRowProps) => {
  return (
    <Flex gap={1} alignItems="center" width="100%" ml={7}>
      <IndentIcon sx={{ color: (theme) => theme.palette.text.disabled }} />
      <Button
        variant="text"
        disabled={disabled}
        sx={{
          color: (theme) => theme.palette.secondary.light,
        }}
        onClick={onClick}
        startIcon={<AddIcon />}
      >
        Add Condition
      </Button>
    </Flex>
  );
};
