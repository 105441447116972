import { useFormContext } from "react-hook-form";
import { CreateWorkspaceFormInput } from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspacePage";
import {
  WorkspaceSelectionConditionIntField,
  WorkspaceSelectionConditionOperator,
  WorkspaceSelectionConditionStringField,
} from "~/operations";
import {
  getIsIntKeySelected,
  getIsStringKeySelected,
} from "~/pages/space/Workspaces/utils";

type UseConditionRowParams = {
  selectionIndex: number;
  conditionIndex: number;
  isReadonly: boolean;
};

export function useConditionRow({
  selectionIndex,
  conditionIndex,
  isReadonly,
}: UseConditionRowParams) {
  const { watch, setValue } = useFormContext<CreateWorkspaceFormInput>();

  const conditionValue = watch(
    `selections.${selectionIndex}.conditions.${conditionIndex}`,
  );

  const conditionValues = (
    (conditionValue?.stringCondition?.values || []).length > 0
      ? conditionValue?.stringCondition?.values || []
      : conditionValue?.intCondition?.values || []
  ).map((v) => String(v));

  const conditionKeys = [
    {
      label: "INFRASTRUCTURE",
      values: [
        {
          label: "Platform",
          value: WorkspaceSelectionConditionStringField.Platform,
        },
        {
          label: "Platform Version",
          value: WorkspaceSelectionConditionStringField.PlatformVersion,
        },
      ],
    },
    {
      label: "RISK & FINDINGS",
      values: [
        {
          label: "Risk Score",
          value: WorkspaceSelectionConditionIntField.RiskScore,
        },
        {
          label: "Base Score",
          value: WorkspaceSelectionConditionIntField.BaseScore,
        },
      ],
    },
  ];

  const stringConditionValuesOptions = [
    {
      label: "AWS",
      value: "AWS",
    },
    {
      label: "AZURE",
      value: "AZURE",
    },
    {
      label: "FIREBASE",
      value: "FIREBASE",
    },
    {
      label: "CUSTOM",
      value: "CUSTOM",
    },
    {
      label: "CUSTOM2",
      value: "CUSTOM2",
    },
  ];

  const intConditionValuesOptions = [
    {
      label: "Critical (80-100)",
      value: 80,
    },
    {
      label: "High (60-80)",
      value: 60,
    },
    {
      label: "Medium (30-60)",
      value: 30,
    },
    {
      label: "Low (1-30)",
      value: 1,
    },
    {
      label: "None (0)",
      value: 0,
    },
  ].map((o) => ({ ...o, value: String(o.value) }));

  const isKeySelected = !!conditionValue?.formKey;

  const isIntKeySelected = getIsIntKeySelected(conditionValue?.formKey);
  const isStringKeySelected = getIsStringKeySelected(conditionValue?.formKey);

  const conditionValuesFieldName:
    | `selections.${number}.conditions.${number}.stringCondition.values`
    | `selections.${number}.conditions.${number}.intCondition.values` =
    isStringKeySelected
      ? `selections.${selectionIndex}.conditions.${conditionIndex}.stringCondition.values`
      : `selections.${selectionIndex}.conditions.${conditionIndex}.intCondition.values`;

  const showIntOperatorDropdown = isKeySelected && isIntKeySelected;
  const showStringOperatorDropdown = isKeySelected && isStringKeySelected;

  const isFirstCondition = conditionIndex === 0;

  const conditionOperator = WorkspaceSelectionConditionOperator.And;

  const handleConditionValueRemove = (value: string) => {
    if (isReadonly) return;

    const newValues = conditionValues.includes(value)
      ? conditionValues.filter((v) => v !== value)
      : [...conditionValues, value];

    setValue(conditionValuesFieldName, newValues);
  };

  const handleConditionKeyRemove = () => {
    if (isReadonly) return;
    setValue(
      `selections.${selectionIndex}.conditions.${conditionIndex}.formKey`,
      "",
    );
    setValue(
      `selections.${selectionIndex}.conditions.${conditionIndex}.stringCondition.values`,
      [],
    );
    setValue(
      `selections.${selectionIndex}.conditions.${conditionIndex}.intCondition.values`,
      [],
    );
  };

  const getSelectedKeyLabel = () => {
    for (const group of conditionKeys) {
      const targetValue = group.values.find(
        (v) => v.value === conditionValue?.formKey,
      );
      if (targetValue) {
        return targetValue.label;
      }
    }
    return "";
  };

  const selectedConditionKeyLabel = getSelectedKeyLabel();

  return {
    showIncludeLabel: isFirstCondition,
    showConditionOperator: !isFirstCondition,
    showConditionKeyLabel: isKeySelected,
    isKeySelected,
    conditionKeys,
    conditionValues,
    conditionValuesOptions: showStringOperatorDropdown
      ? stringConditionValuesOptions
      : intConditionValuesOptions,
    showStringOperatorDropdown,
    showIntOperatorDropdown,
    selectedConditionKeyLabel,
    conditionOperator,
    conditionValuesFieldName,
    handleConditionValueRemove,
    handleConditionKeyRemove,
  };
}
