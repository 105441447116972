import { SyntheticEvent, useCallback, useState } from "react";
import { alpha, useTheme } from "@mui/material";
import { getColorByCompletion } from "~/lib/colors";
import {
  ComplianceFrameworkMutationAction,
  ComplianceFrameworkState,
  GetListFrameworksDocument, // might still need this
  UploadFrameworkInput,
  useApplyFrameworkMutation,
  useDeleteFrameworkMutation,
  useDownloadFrameworkLazyQuery,
  useUploadFrameworkMutation,
} from "~/operations";
import { useFrameworkIcon } from "../framework/framework-icon";
import { IconButtonType } from "../components/DynamicButtonIcon";
import { CircularIconButton } from "../components/CircularIconButton";
import { useSnackbar } from "notistack";
import { Space } from "~/lib/types";
import { ComplianceFramework } from "../types";

type useFrameworkCardProps = {
  framework: ComplianceFramework;
  officialFramework?: boolean;
  space: Space;
};

export function useFrameworkCard({
  framework,
  officialFramework,
  space,
}: useFrameworkCardProps) {
  const theme = useTheme();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [showEditTools, setShowEditTools] = useState(false);
  const { iconKey } = useFrameworkIcon({ framework });

  // framework tag object looks like this
  // [
  //   {
  //     __typename: "Tag",
  //     key: "mondoo.com/num_assets",
  //     value: "9",
  //   },
  //   {
  //     __typename: "Tag",
  //     key: "mondoo.com/num_controls",
  //     value: "121",
  //   },
  // ];
  // filter through the tags and create an array of key value pairs
  // that look like [{key: assets, value: 9}, {key: controls, value: 121}]

  const frameworkStats = Object.values(framework.score?.tags || {})
    .map((tag) => {
      // filter through the tags and create an array of key value pairs
      if (tag.key === "mondoo.com/authors" || tag.key === "mondoo.com/icon") {
        return null;
      }
      // that look like [{key: assets, value: 9}, {key: controls, value: 121}]
      return { key: tag.key.split("_").pop() || tag.key, value: tag.value };
    })
    .flatMap((x) => x ?? []);

  // if frameworkStats is empty, return controls, assets, and exceptions with - values
  if (frameworkStats.length === 0) {
    frameworkStats.push(
      ...["controls", "assets", "exceptions"].map((key) => ({
        key,
        value: "-",
      })),
    );
  }

  // sort the framework stats by key in order of controls, assets, and then exceptions
  const order = ["controls", "assets"];
  frameworkStats.sort((a, b) => {
    const aIndex = order.indexOf(a.key);
    const bIndex = order.indexOf(b.key);
    return (
      (aIndex === -1 ? order.length : aIndex) -
      (bIndex === -1 ? order.length : bIndex)
    );
  });

  const authors = framework.authors?.map((author) => author.name).join(", ");
  const completion = framework.score?.completion || 0;

  const [
    downloadFramework,
    { loading: downloadFrameworkLoading, error: downloadFrameworkError },
  ] = useDownloadFrameworkLazyQuery({
    variables: {
      input: { mrn: framework.mrn, scopeMrn: space.mrn },
    },
  });

  const [removeFramework] = useApplyFrameworkMutation({
    refetchQueries: [GetListFrameworksDocument],
  });

  const [uploadFramework] = useUploadFrameworkMutation({
    refetchQueries: [GetListFrameworksDocument],
  });

  const [deleteFramework] = useDeleteFrameworkMutation({
    variables: { input: { mrn: framework.mrn } },
    refetchQueries: [GetListFrameworksDocument],
  });

  const [canExport, setCanExport] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();

  if (downloadFrameworkError && canExport) {
    enqueueSnackbar("Framework download failed", { variant: "error" });
    setCanExport(false);
  }

  const handleCancelDelete = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteDialogOpen(false);
  };

  const handleDownloadFramework = async (
    e: SyntheticEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setCanExport(true);

    // TODO:: create reusable function and reuse it here and in policy-download-button.tsx component
    downloadFramework().then(({ data }) => {
      if (
        canExport &&
        data != undefined &&
        data.downloadFramework != undefined
      ) {
        const yamlData = data.downloadFramework;
        // only allow one download per click
        setCanExport(false);
        // a bunch of weird magic to make the download work
        // https://stackoverflow.com/questions/44656610/download-a-string-as-txt-file-in-react
        const file = new Blob([yamlData.yaml], {
          type: "text/yaml",
        });
        const element = document.createElement("a");
        element.href = URL.createObjectURL(file);

        // generates a filename that should sort properly on the file system
        function makeFileName(): string {
          const now = new Date();
          const fileDate = parseInt((now.getTime() / 1000).toString());
          const fileName = `${framework.title}_${fileDate}.mql.yaml`;

          return fileName;
        }

        element.download = makeFileName();
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();

        // this timeout is here to avoid a render warning, so this library does not render during render
        window.setTimeout(function () {
          enqueueSnackbar(
            "Success! If you do not see your framework download, make sure your browser allows downloads from this site.",
            { variant: "success" },
          );
        }, 0);
      }
    });
  };

  const handleReplaceFramework = useCallback((input: UploadFrameworkInput) => {
    return uploadFramework({
      variables: {
        input: { spaceMrn: input.spaceMrn, dataurl: input.dataurl },
      },
    });
  }, []);

  const handleDeleteFrameworkBegin = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteDialogOpen(true);
  };

  const handleRemoveFrameworkBegin = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteDialogOpen(true);
  };

  const handleDeleteFrameworkConfirm = async (
    e: SyntheticEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await deleteFramework();
      setDeleteDialogOpen(false);
    } catch {
      console.error("Error deleting framework");
    }
  };

  const handleRemoveFrameworkConfirm = async (
    e: SyntheticEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await removeFramework({
        variables: {
          input: {
            action: ComplianceFrameworkMutationAction.Disable,
            frameworkMrn: framework.mrn,
            scopeMrn: space.mrn,
          },
        },
      });
      enqueueSnackbar("Framework removed", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Failed to remove framework", { variant: "error" });
    }
  };

  const toggleShowEditTools = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (showEditTools) {
      setShowEditTools(false);
    } else {
      setShowEditTools(true);
    }
  };

  const getBackgroundColor = () => {
    return framework.state === ComplianceFrameworkState.Preview
      ? alpha(theme.palette.unknown.main, 0.1)
      : () => {
          const color = getColorByCompletion(
            theme,
            framework.score?.completion || 0,
          );
          return alpha(color, 0.1);
        };
  };

  const getCompletionTextColor = () => {
    return framework.state === ComplianceFrameworkState.Preview
      ? theme.palette.unknown.light
      : () => getColorByCompletion(theme, framework.score?.completion || 0);
  };

  const downloadFrameworkButton = () => {
    return (
      <CircularIconButton
        title="Download Framework"
        iconType={IconButtonType.Download}
        onClick={handleDownloadFramework}
      />
    );
  };

  const displayInteractions = () => {
    if (officialFramework) {
      return (
        <CircularIconButton
          title="Download Framework"
          iconType={IconButtonType.Download}
          onClick={handleDownloadFramework}
        />
      );
    }
    if (showEditTools) {
      return (
        <CircularIconButton
          title="Close Edit Tools"
          iconType={IconButtonType.Close}
          onClick={toggleShowEditTools}
        />
      );
    } else {
      return (
        <CircularIconButton
          title="Open Edit Tools"
          iconType={IconButtonType.Edit}
          onClick={toggleShowEditTools}
        />
      );
    }
  };

  return {
    loading: {
      deleteProcess: false, // placeholder for when deletion mutation is in progress
      downloadFramework: downloadFrameworkLoading,
    },
    data: {
      authors,
      completion,
      iconKey,
      frameworkStats,
      frameworkMrn: framework.mrn,
      name: framework.title,
      tooltipTitle: framework.title.length > 25 ? framework.title : "",
      displayInteractions: displayInteractions(),
      uploadedAt: framework.uploadedAt,
      state: framework.state,
    },
    downloadFrameworkButton,
    showEditTools,
    setShowEditTools,
    get: {
      backgroundColor: getBackgroundColor,
      completionTextColor: getCompletionTextColor,
    },
    handle: {
      deleteDialogOpen,
      cancelDelete: handleCancelDelete,
      downloadFramework: handleDownloadFramework,
      replaceFramework: handleReplaceFramework,
      removeFrameworkBegin: handleRemoveFrameworkBegin,
      removeFramework: handleRemoveFrameworkConfirm,
      deleteFrameworkBegin: handleDeleteFrameworkBegin,
      deleteFrameworkConfirm: handleDeleteFrameworkConfirm,
      toggleShowEditTools: toggleShowEditTools,
    },
  };
}
