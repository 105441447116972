import { MenuList } from "@mui/material";
import { ReactNode } from "react";

type BaseMenuListProps = { children: ReactNode };

export const BaseMenuList = ({ children }: BaseMenuListProps) => {
  return (
    <MenuList
      disablePadding
      sx={{
        maxHeight: "calc(100vh - 152px)",
        overflow: "auto",
        p: 2,
        pt: 0,
        mt: 2,
      }}
    >
      {children}
    </MenuList>
  );
};
