import { OpenDropdownButton } from "../OpenDropdownButton";
import { Box } from "@mui/material";
import { BaseAutocomplete } from "~/pages/space/Workspaces/components/QueryBuilder/AutocompleteDropdown/BaseAutocomplete";
import { CheckboxMenuItem } from "~/pages/space/Workspaces/components/QueryBuilder/AutocompleteDropdown/CheckboxMenuItem";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { CreateWorkspaceFormInput } from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspacePage";
import { BaseMenuList } from "~/pages/space/Workspaces/components/QueryBuilder/AutocompleteDropdown/BaseMenuList";

type ValuesDropdownAutocompleteProps = {
  values: string[];
  name:
    | `selections.${number}.conditions.${number}.stringCondition.values`
    | `selections.${number}.conditions.${number}.intCondition.values`;
  options: Array<{ label: string; value: string }>;
  anchorRef: React.RefObject<HTMLButtonElement>;
};

export const ValuesDropdownAutocomplete = ({
  values,
  name,
  options,
  anchorRef,
}: ValuesDropdownAutocompleteProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { setValue, register } = useFormContext<CreateWorkspaceFormInput>();

  useEffect(() => {
    register(name);
  }, [register]);

  const onItemClick = (value: unknown) => {
    const castedCurrentValues = values.map((cv) => String(cv));

    const isValueSelected = castedCurrentValues.includes(value as string);
    if (isValueSelected) {
      setValue(
        name,
        castedCurrentValues.filter((cv) => cv !== value),
      );
    } else {
      setValue(name, [...castedCurrentValues, value as string]);
    }
  };

  return (
    <Box>
      <OpenDropdownButton
        isOpen={isOpen}
        onClick={() => setIsOpen((open) => !open)}
      />
      <Box sx={{ maxWidth: "400px" }}>
        <BaseAutocomplete
          showKeyboardHint
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
          anchorRef={anchorRef}
          onSearchInputChange={() => {}}
          renderContent={() => (
            <BaseMenuList>
              {options.map((option) => (
                <CheckboxMenuItem
                  label={option.label}
                  value={option.value}
                  isChecked={values.includes(option.value)}
                  onClick={onItemClick}
                  key={option.label}
                />
              ))}
            </BaseMenuList>
          )}
        />
      </Box>
    </Box>
  );
};
