import { Checkbox, ListItemText } from "@mui/material";
import { BaseMenuItem } from "./BaseMenuItem";

export type CheckboxMenuItemProps = {
  label: string;
  value: unknown;
  isChecked: boolean;
  onClick?: (value: unknown) => void;
};

export const CheckboxMenuItem = ({
  label,
  value,
  isChecked,
  onClick,
}: CheckboxMenuItemProps) => {
  return (
    <BaseMenuItem label={label} value={value} onClick={onClick}>
      <Checkbox
        checked={isChecked}
        disableRipple
        sx={{
          "&.MuiCheckbox-root": {
            p: 0.5,
          },
        }}
      />
      <ListItemText primary={label} sx={{ ml: 1 }} />
    </BaseMenuItem>
  );
};
