import { AddIcon } from "~/components/icons";
import { Button } from "@mui/material";
import React, { MouseEvent } from "react";

type AddConditionButtonProps = {
  isOpen: boolean;
  anchorRef?: React.RefObject<HTMLButtonElement>;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const OpenDropdownButton = ({
  isOpen,
  anchorRef,
  onClick,
}: AddConditionButtonProps) => {
  return (
    <Button
      variant="outlined-contained"
      color="primary"
      ref={anchorRef}
      onClick={onClick}
      sx={{
        minWidth: "40px",
        height: "40px",
        p: 0,
      }}
    >
      <AddIcon fontSize="small" sx={{ rotate: isOpen ? "45deg" : "0deg" }} />
    </Button>
  );
};
