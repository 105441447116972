import { OpenDropdownButton } from "../OpenDropdownButton";
import { Box } from "@mui/material";
import { BaseAutocomplete } from "~/pages/space/Workspaces/components/QueryBuilder/AutocompleteDropdown/BaseAutocomplete";
import { MenuItemsGroup } from "~/pages/space/Workspaces/components/QueryBuilder/AutocompleteDropdown/MenuItemsGroup";
import React, { useEffect, useRef, useState } from "react";
import {
  WorkspaceSelectionConditionIntField,
  WorkspaceSelectionConditionStringField,
} from "~/operations";
import { useFormContext } from "react-hook-form";
import { CreateWorkspaceFormInput } from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspacePage";
import { BaseMenuList } from "~/pages/space/Workspaces/components/QueryBuilder/AutocompleteDropdown/BaseMenuList";

type GroupItemValue = {
  label: string;
  value:
    | WorkspaceSelectionConditionStringField
    | WorkspaceSelectionConditionIntField;
};

export type KeysDropdownAutocomplete = {
  name: `selections.${number}.conditions.${number}.formKey`;
  groups: Array<{ label: string; values: Array<GroupItemValue> }>;
};

export const KeysDropdownAutocomplete = ({
  name,
  groups,
}: KeysDropdownAutocomplete) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { setValue, register } = useFormContext<CreateWorkspaceFormInput>();

  useEffect(() => {
    register(name);
  }, [register]);

  const onItemClick = (value: unknown) => {
    setValue(
      name,
      value as
        | WorkspaceSelectionConditionStringField
        | WorkspaceSelectionConditionIntField,
    );
    setIsOpen(false);
  };

  return (
    <Box>
      <OpenDropdownButton
        isOpen={isOpen}
        anchorRef={anchorRef}
        onClick={() => setIsOpen((open) => !open)}
      />
      <Box sx={{ maxWidth: "400px", position: "relative" }}>
        <BaseAutocomplete
          showKeyboardHint
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
          anchorRef={anchorRef}
          onSearchInputChange={(_) => {}}
          renderContent={() => (
            <BaseMenuList>
              {groups.map((group) => (
                <MenuItemsGroup
                  groupTitle={group.label}
                  onClick={onItemClick}
                  items={group.values}
                  key={group.label}
                />
              ))}
            </BaseMenuList>
          )}
        />
      </Box>
    </Box>
  );
};
