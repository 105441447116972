import { ListItemText, ListSubheader } from "@mui/material";
import {
  BaseMenuItem,
  BaseMenuItemProps,
} from "~/pages/space/Workspaces/components/QueryBuilder/AutocompleteDropdown/BaseMenuItem";

export type MenuItemsGroupProps = {
  groupTitle: string;
  items: Array<BaseMenuItemProps>;
  onClick?: (value: unknown) => void;
};

export const MenuItemsGroup = ({
  items,
  groupTitle,
  onClick,
}: MenuItemsGroupProps) => {
  return (
    <>
      <ListSubheader
        sx={{ backgroundColor: "background.default" }}
        tabIndex={0}
      >
        {groupTitle}
      </ListSubheader>
      {items.map((item) => {
        return (
          <BaseMenuItem
            label={item.label}
            value={item.value}
            onClick={onClick}
            key={item.label}
          >
            <ListItemText primary={item.label} />
          </BaseMenuItem>
        );
      })}
    </>
  );
};
